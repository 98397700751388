import { useHistory } from 'react-router-dom';

import css from './Pages.module.scss';
import { addCSSClassName } from '../../assets/js/helpers';

import Center from '../Helpers/Center/Center';
import TextBox from '../TextBox/TextBox';
import Button from '../Buttons/Button';

import { pageNotFoundContent as content } from '../../assets/js/content';

import useLocale from '../../hooks/useLocale';

const PageNotFound = () => {
  const { lang } = useLocale();
  const navigateTo = useHistory().push;

  return (
    <div className="u-center-text">
      <Center>
        <TextBox>
          <h1 className="heading__primary " style={{ color: '#a2213b' }}>
            {content.heading[lang]}
          </h1>
          <p className="u-margin-bottom-ultra-sm">{content.text[lang]}</p>
          <Button onClick={() => navigateTo('/')}>{content.btnText[lang]}</Button>
        </TextBox>
      </Center>
    </div>
  );
};

export default PageNotFound;
