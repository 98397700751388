import slugify from 'slugify';

export const addCSSClassName = (stylesModule, className, ...helperClasses) => {
  const finalClass = stylesModule[className];
  const finalClasses = `${finalClass ? finalClass : ''} ${
    helperClasses ? helperClasses.join(' ') : ''
  }`.trim();

  return finalClasses ? finalClasses : '';
};

// Slideshow section functionality
export const slideshow = (slides, btnLeft, btnRight, timer) => {
  const maxSlide = slides.length;
  let curSlide = 0;

  slides.forEach((s, i) => {
    s.style.transform = `translateX(${100 * i}%)`;
  });

  function goToSlide(slide) {
    slides.forEach((s, i) => {
      s.style.transform = `translateX(${100 * (i - slide)}%)`;
    });
  }

  function slideRight() {
    if (curSlide === maxSlide - 1) {
      curSlide = 0;
    } else {
      curSlide++;
    }

    goToSlide(curSlide);
  }

  function slideLeft() {
    if (curSlide === 0) {
      curSlide = maxSlide - 1;
    } else {
      curSlide--;
    }

    goToSlide(curSlide);
  }

  if (typeof timer === 'number') {
    setInterval(() => {
      slideRight();
    }, timer * 1000);
  }

  btnLeft.forEach(btn => {
    btn.addEventListener('click', () => {
      slideLeft();
    });
  });

  btnRight.forEach(btn => {
    btn.addEventListener('click', () => {
      slideRight();
    });
  });
};

export const generateId = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

export const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export const sendDataToNetlify = inputs => {
  return fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({ 'form-name': 'contact-form', ...inputs }),
  });
};

export const sendDataToNetlifyAlternative = form => {
  const myForm = new FormData(form);

  return fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams(myForm).toString(),
  });
};

export const generateUrlPath = (heading, prepend) => {
  const slugOptions = {
    lower: true,
    strict: true,
  };

  if (prepend) {
    return `/${prepend}/${slugify(heading, slugOptions)}`;
  }

  return slugify(heading, slugOptions);
};
