import css from './LanguageBar.module.scss';
import { addCSSClassName } from '../../assets/js/helpers';
import useLocale from '../../hooks/useLocale';

const LanguageBar = () => {
  const langs = ['BG', 'EN'];
  const { lang, switchLanguage } = useLocale();

  const changeLanguageHandler = () => {
    langs.forEach(lng => lang !== lng && switchLanguage(lng));
  };

  return (
    <button
      className={`${addCSSClassName(css, 'lang-button')}`}
      type="button"
      onClick={changeLanguageHandler}
    >
      <span className={`${addCSSClassName(css, 'lang-text', 'lang')}`}>{lang}</span>
    </button>
  );
};

export default LanguageBar;
