import { useEffect, useState, useCallback } from 'react';
import css from './Slider.module.scss';
import 'react-slideshow-image/dist/styles.css';
import { addCSSClassName } from '../../assets/js/helpers';
import useLocale from '../../hooks/useLocale';

const Slider = ({ images, style, className }) => {
  const [curSlide, setCurSlide] = useState(0);
  const [intervalTimer, setIntervalTimer] = useState(0);
  const { lang } = useLocale();

  const resetTimer = useCallback(slideCallback => {
    if (intervalTimer) {
      clearInterval(intervalTimer);

      const newInterval = setInterval(() => slideCallback(), 4000);

      setIntervalTimer(newInterval);
    } else {
      slideCallback();
    }
  }, []);

  const btnRightClickHandler = useCallback(() => {
    const moveToRightSlide = () =>
      setCurSlide(prev => {
        if (prev < images.length - 1) return prev + 1;
        else return 0;
      });

    resetTimer(moveToRightSlide);
  }, [images.length, resetTimer]);

  const btnLeftClickHandler = useCallback(() => {
    const moveToLeftSlide = () =>
      setCurSlide(prev => {
        if (prev > 0) return prev - 1;
        else return images.length - 1;
      });

    resetTimer(moveToLeftSlide);
  }, [images.length, resetTimer]);

  useEffect(() => {
    const timer = setInterval(() => {
      btnRightClickHandler();
    }, 4000);

    setIntervalTimer(timer);

    return () => {
      clearInterval(timer);
    };
  }, [btnRightClickHandler, curSlide]);

  const dotClickHandler = e => {
    const dotIndex = +e.target.getAttribute('dot-index');

    setCurSlide(dotIndex);
  };

  if (images.length < 1) return <></>;

  return (
    <div style={{ ...style }} className={addCSSClassName(css, 'slider', className)}>
      <span
        onClick={btnLeftClickHandler}
        className={addCSSClassName(css, 'slider__arr', addCSSClassName(css, 'slider__arr--left'))}
      >
        <span>&larr;</span>
      </span>
      {images.map((img, i) => (
        <img
          key={Math.random() * 32 + 45}
          className={addCSSClassName(css, 'slider__slide')}
          src={img}
          alt={lang === 'BG' ? 'Презентационна снимка' : 'Presentational image'}
          style={{ transform: `translateX(${100 * (i - curSlide)}%)` }}
        />
      ))}

      <span
        onClick={btnRightClickHandler}
        className={addCSSClassName(css, 'slider__arr', addCSSClassName(css, 'slider__arr--right'))}
      >
        <span>&rarr;</span>
      </span>

      <div className={addCSSClassName(css, 'slider__dots')}>
        {images.map((_, i) => (
          <div
            key={Math.random()}
            dot-index={i}
            onClick={dotClickHandler}
            className={addCSSClassName(
              css,
              'slider__dot',
              `${curSlide === i ? addCSSClassName(css, 'slider__dot--active') : ''}`
            )}
          >
            &nbsp;
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
