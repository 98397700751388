import { addCSSClassName } from '../../../assets/js/helpers';
import css from './MobNavIcon.module.scss';

const MobNavIcon = props => {
  const { style } = props;

  const clickHandler = e => {
    e.preventDefault();

    const modal = document.getElementById('mob-nav-modal');

    modal.classList.add('mob-nav-modal-active');
  };

  return (
    <button className={addCSSClassName(css, 'mob-nav-icon')} type="button" onClick={clickHandler}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={style || {}}
        width="80"
        height="80"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
        />
      </svg>
    </button>
  );
};

export default MobNavIcon;
