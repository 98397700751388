import { Fragment } from 'react';

import { addCSSClassName } from '../../assets/js/helpers';

import useLocale from '../../hooks/useLocale';

import TextBox from '../TextBox/TextBox';

const RoomTerms = props => {
  const { content, css } = props;
  const { lang } = useLocale();

  return (
    <Fragment>
      <div className={addCSSClassName(css, 'pages__room-terms', 'grid', 'grid--3-cols')}>
        {content.map(term => (
          <TextBox classes="align-author-center px-sm" key={Math.random()}>
            <h3 className="heading__tertiary">{term.heading[lang]}</h3>
            <p>{term.description[lang]}</p>
          </TextBox>
        ))}
      </div>
    </Fragment>
  );
};

export default RoomTerms;
