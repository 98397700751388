import { useEffect } from 'react';
import { Fragment } from 'react';

const ScrollToMain = ({ children }) => {
  useEffect(() => {
    const mainEl = document?.querySelector('main');

    if (mainEl) {
      const mainElCoords = mainEl.getBoundingClientRect();

      window.scrollTo({
        top: window.scrollY + mainElCoords.top,
        left: window.scrollX + mainElCoords.left,
        behavior: 'smooth',
      });
    }
  }, [children]);

  return <Fragment>{children}</Fragment>;
};

export default ScrollToMain;
