export const contacts = {
  email: {
    text: "hotelvivian.kiten@gmail.com",
    link: "mailto:hotelvivian.kiten@gmail.com",
  },

  phone: {
    text: "+359 877 17 09 24",
    link: "tel:359877170924",
  },
};

export default contacts;
