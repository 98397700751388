import useLocale from '../../../hooks/useLocale';
import FooterNav from '../../Navigation/FooterNav/FooterNav';
import css from './Footer.module.scss';

import { footerContent as content } from '../../../assets/js/content';

const Footer = () => {
  const { lang } = useLocale();
  const { copyText, navigation } = content;
  const footerNav = navigation[lang].items;

  return (
    <footer className={`${css.footer}`}>
      <span className={`${css['footer__copy']}`}>{copyText[lang]}</span>
      {/* {renderMenu()} */}
      <FooterNav nav={footerNav} />
    </footer>
  );
};

export default Footer;
