import { Fragment } from 'react';
import { addCSSClassName, generateUrlPath } from './helpers';
import pagesCSS from '../../Components/Pages/Pages.module.scss';

import logo from '../../assets/images/logo-cut-white-clean.jpg';
import navigation from './navigation';

// Rooms images
import imgTripleRoom1 from '../images/Rooms/Triple Room/img1.jpg';
import imgTripleRoom2 from '../images/Rooms/Triple Room/img2.jpg';
import imgTripleRoom3 from '../images/Rooms/Triple Room/img3.jpg';
import imgTripleRoom4 from '../images/Rooms/Triple Room/img4.jpg';
import imgTripleRoom5 from '../images/Rooms/Triple Room/img5.jpg';
import imgTripleRoom7 from '../images/Rooms/Triple Room/img7.jpg';
import imgTripleRoom8 from '../images/Rooms/Triple Room/img8.jpg';
import imgTripleRoom10 from '../images/Rooms/Triple Room/img10.jpg';
import imgTripleRoom11 from '../images/Rooms/Triple Room/img11.jpg';
import imgTripleRoom12 from '../images/Rooms/Triple Room/img12.jpg';
import imgTripleRoom13 from '../images/Rooms/Triple Room/img13.jpg';

// New Triple room images
import imgTripleRoomNew1 from '../images/Rooms/Triple Room/new/1.jpg';
import imgTripleRoomNew2 from '../images/Rooms/Triple Room/new/2.jpg';
import imgTripleRoomNew3 from '../images/Rooms/Triple Room/new/3.jpg';
import imgTripleRoomNew4 from '../images/Rooms/Triple Room/new/4.jpg';

import imgDoubleRoom2 from '../images/Rooms/Double Room/img2.jpg';
import imgDoubleRoom3 from '../images/Rooms/Double Room/img3.jpg';
import imgDoubleRoom4 from '../images/Rooms/Double Room/104957.jpg';

// Apartment images
import apartmentImg1 from '../images/Rooms/Apartment/ap_1.jpg';
import apartmentImg2 from '../images/Rooms/Apartment/ap_2.jpg';
import apartmentImg3 from '../images/Rooms/Apartment/ap_3.jpg';
import apartmentImg4 from '../images/Rooms/Apartment/ap_4.jpg';
import apartmentImg5 from '../images/Rooms/Apartment/ap_5.jpg';
import apartmentImg6 from '../images/Rooms/Apartment/ap_6.jpg';
import apartmentImg7 from '../images/Rooms/Apartment/ap_7.jpg';
import apartmentImg8 from '../images/Rooms/Apartment/ap_8.jpg';
import apartmentImg9 from '../images/Rooms/Apartment/ap_9.jpg';
import apartmentImg10 from '../images/Rooms/Apartment/ap_10.jpg';
import apartmentImg11 from '../images/Rooms/Apartment/ap_11.jpg';

// Budget room images
import budgetRoomImg1 from '../images/Rooms/Budget Room/b_room_1.jpg';
import budgetRoomImg2 from '../images/Rooms/Budget Room/b_room_2.jpg';
import budgetRoomImg3 from '../images/Rooms/Budget Room/b_room_3.jpg';
import budgetRoomImg4 from '../images/Rooms/Budget Room/b_room_4.jpg';
import budgetRoomImg5 from '../images/Rooms/Budget Room/b_room_5.jpg';

export const homePageContent = {
  quoteHeading: {
    BG: '“За морето е характерно да прониква в душата”',
    EN: '“It is characteristic of the sea to penetrate into the soul”',
  },

  quoteAuthor: {
    BG: 'Хана Кант',
    EN: 'Hannah Kant',
  },

  hotelDescriptionHeading: {
    BG: (
      <span>
        Къща за гости "Вивиан" - гр.Китен, ул."Акация"№2 <br />В непосредствена близост до
        кметството на гр.Китен <br />
        РЕНОВИРАНА 2022г.
      </span>
    ),
    EN: (
      <span>
        Guest House "Vivian" - Kiten, 2 Acacia Str. <br />
        In close proximity to the town hall of Kiten
        <br />
        RENOVATED in 2022.
      </span>
    ),
  },

  hotelDescriptionText: {
    BG: (
      <Fragment>
        <p className={addCSSClassName(pagesCSS, 'pages__text')} style={{ textAlign: 'left' }}>
          Къща за гости "Вивиан" е разположена на 300 метра от прекрасния Южен плаж  и на 500м.от
          плаж Атлиман. В сърцето на града и в същото време на тиха и спокойна улица, което ще Ви
          предостави отлични условия за морска почивка. Къщата е реновирана и изцяло обзаведена през
          2022г.
        </p>

        <p className={addCSSClassName(pagesCSS, 'pages__text')} style={{ textAlign: 'left' }}>
          Гостувайки при нас, ще се потопите в очарователната атмосфера на малкият семеен хотел - с
          персонално отношение към всеки  гост, уют и спокойствие.Ние ще се постараем да се
          почувствате по възможно най- комфортния начин и да се насладите на незабравими морски
          изживявания.
        </p>

        <p className={addCSSClassName(pagesCSS, 'pages__text')} style={{ textAlign: 'left' }}>
          Тук ще откриете любимото си място за почивка на Черноморието . Разполагаме с помещения
          подходящи за всеки.
        </p>
      </Fragment>
    ),

    EN: (
      <Fragment>
        <p className={addCSSClassName(pagesCSS, 'pages__text')} style={{ textAlign: 'left' }}>
          Vivian Guest House is located 300 meters from the beautiful South Beach and 500 meters
          from Atliman Beach. Located in the heart of the city and at the same time on a quiet
          street, which will provide you with excellent conditions for a sea holiday. The house was
          renovated and fully furnished in 2022.
        </p>

        <p className={addCSSClassName(pagesCSS, 'pages__text')} style={{ textAlign: 'left' }}>
          Visiting us, you will immerse yourself in the charming atmosphere of the small family
          guest house - with a personal attitude to each guest, comfort and tranquility. We will try
          to make you feel as comfortable as possible and enjoy unforgettable sea experiences.
        </p>

        <p className={addCSSClassName(pagesCSS, 'pages__text')} style={{ textAlign: 'left' }}>
          Here you will find your favorite vacation spot on the Black Sea coast. We have rooms
          suitable for everyone.
        </p>
      </Fragment>
    ),
  },

  tables: [
    {
      heading: {
        BG: 'Ценоразпис (на нощувка)',
        EN: 'Pricing (per night)',
      },

      listDescription: [
        {
          BG: 'Самостоятелно ползващ помещение, заплаща 80% от цената му при пълен капацитет!',
          EN: 'Independent user of a room, pays 80% of its price at full capacity!',
        },

        {
          BG: 'Деца от 0 до 6 години се настаняват безплатно, но без ползане на самостоятелно легло!',
          EN: 'Children from 0 to 6 years stay for free, but without the use of a separate bed!',
        },

        {
          BG: 'Настаняването на пети човек в апартамента е на разтегателен диван!',
          EN: 'The accommodation of the fifth person in the apartment is on a sofa bed!',
        },

        {
          BG: 'Паркоместа се заявяват предварително, и се осигуряват до изчерпването им. Цена за едно денонощие 5,00лв.!',
          EN: 'Parking spaces are requested in advance and are secured until they are exhausted. Price for 24 hours is BGN 5.00!',
        },

        {
          BG: 'Допълнително легло в помещение с такава възможност - 15,00лв.с вкл.закуска!',
          EN: 'An additional bed in a room with such an option - BGN 15.00 including breakfast!',
        },

        {
          BG: 'Закуската е на блок маса, но при запълване по-малко от 10% от местата се приготвя индивидуална закуска!',
          EN: 'Breakfast is at a buffet table, but if less than 10% of the seats are filled, an individual breakfast is prepared!',
        },

        {
          BG: 'Домашни любимци не се допускат!',
          EN: 'Pets are not allowed!',
        },
      ],

      tableContent: {
        headerText: [
          {
            BG: '',
            EN: '',
          },

          {
            BG: 'Двойна стая с две отделни легла (цена за двама) с включена закуска',
            EN: 'Double room with two separate beds (price for two) with breakfast included',
          },

          {
            BG: 'Двойна стая с две отделни легла (цена за двама) без включена закуска',
            EN: 'Double room with two separate beds (price for two) without breakfast included',
          },

          {
            BG: 'Тройна стая (цена за трима) с включена закуска',
            EN: 'Triple room (price for three) with breakfast included',
          },

          {
            BG: 'Тройна стая (цена за трима) без включена закуска',
            EN: 'Triple room (price for three) without breakfast included',
          },

          {
            BG: 'Тройна стая делукс със спалня и единично легло (цена за трима) с включена закуска',
            EN: 'Deluxe triple room with a bedroom and a single bed (price for three) with breakfast included',
          },

          {
            BG: 'Тройна стая делукс със спалня и единично легло (цена за трима) без включена закуска',
            EN: 'Deluxe triple room with a bedroom and a single bed (price for three) without breakfast included',
          },

          {
            BG: 'Апартамент (макс.капацитет 5 души) с включена закуска *едното място е на разтегателен диван',
            EN: 'Apartment (max. capacity 5 people) with breakfast included *one seat is on a sofa bed',
          },

          {
            BG: 'Апартамент (макс.капацитет 5 души) без включена закуска *едното място е на разтегателен диван',
            EN: 'Apartment (max. capacity 5 people) without breakfast included *one seat is on a sofa bed',
          },

          {
            BG: 'Бюджетна стая *без климатик със  спалня 140/200 (цена за двама) с включена закуска ',
            EN: 'Budget room *without air conditioning with bedroom 140/200 (price for two) with breakfast included',
          },

          {
            BG: 'Бюджетна стая *без климатик със  спалня 140/200 (цена за двама) без включена закуска',
            EN: 'Budget room *without air conditioning with bedroom 140/200 (price for two) without breakfast included',
          },

          {
            BG: 'Дете до 11 годишна възраст настанено на самостоятелно легло с включена закуска',
            EN: 'Child up to 11 years of age accommodated in a separate bed with breakfast included',
          },

          {
            BG: 'Дете до 11 годишна възраст настанено на самостоятелно легло без включена закуска',
            EN: 'Child up to 11 years of age accommodated in a separate bed without breakfast included',
          },
        ],

        mainContentRows: [
          [
            {
              BG: 'от 01.06.2024 до 30.06.2024',
              EN: '01.06.2024 to 30.06.2024',
            },

            {
              BG: '60лв',
              EN: 'BGN 60',
            },

            {
              BG: '50лв',
              EN: 'BGN 50',
            },

            {
              BG: '75лв',
              EN: 'BGN 75',
            },
            {
              BG: '60лв',
              EN: 'BGN 60',
            },

            {
              BG: '90лв',
              EN: 'BGN 90',
            },

            {
              BG: '75лв',
              EN: 'BGN 75',
            },

            {
              BG: '150лв',
              EN: 'BGN 150',
            },

            {
              BG: '125лв',
              EN: 'BGN 125',
            },

            {
              BG: '50лв',
              EN: 'BGN 40',
            },

            {
              BG: '40лв',
              EN: 'BGN 40',
            },

            {
              BG: '15лв',
              EN: 'BGN 12.50',
            },

            {
              BG: '12,50лв',
              EN: 'BGN 12.50',
            },
          ],

          [
            {
              BG: 'от 01.07.2024 до 15.07.2024',
              EN: '01.07.2024 to 15.07.2024',
            },

            {
              BG: '80лв',
              EN: 'BGN 80',
            },

            {
              BG: '70лв',
              EN: 'BGN 70',
            },

            {
              BG: '105лв',
              EN: 'BGN 105',
            },
            {
              BG: '90лв',
              EN: 'BGN 90',
            },

            {
              BG: '120лв',
              EN: 'BGN 120',
            },

            {
              BG: '105лв',
              EN: 'BGN 105',
            },

            {
              BG: '200лв',
              EN: 'BGN 200',
            },

            {
              BG: '175лв',
              EN: 'BGN 175',
            },

            {
              BG: '60лв',
              EN: 'BGN 15',
            },

            {
              BG: '50лв',
              EN: 'BGN 50',
            },

            {
              BG: '20лв',
              EN: 'BGN 20',
            },

            {
              BG: '17,50лв',
              EN: 'BGN 17.50',
            },
          ],

          [
            {
              BG: 'от 16.07.2024 до 25.08.2024',
              EN: '16.07.2024 to 25.08.2024',
            },

            {
              BG: '100лв',
              EN: 'BGN 100',
            },

            {
              BG: '90лв',
              EN: 'BGN 90',
            },

            {
              BG: '135лв',
              EN: 'BGN 135',
            },
            {
              BG: '120лв',
              EN: 'BGN 120',
            },

            {
              BG: '150лв',
              EN: 'BGN 150',
            },

            {
              BG: '135лв',
              EN: 'BGN 135',
            },

            {
              BG: '230лв',
              EN: 'BGN 230',
            },

            {
              BG: '205лв',
              EN: 'BGN 205',
            },

            {
              BG: '70лв',
              EN: 'BGN 70',
            },

            {
              BG: '60лв',
              EN: 'BGN 60',
            },

            {
              BG: '25лв',
              EN: 'BGN 25',
            },

            {
              BG: '22,50лв',
              EN: 'BGN 22.50',
            },
          ],

          [
            {
              BG: 'от 26.08.2024 до 15.09.2024',
              EN: '26.08.2024 to 15.09.2024',
            },

            {
              BG: '80лв',
              EN: 'BGN 80',
            },

            {
              BG: '70лв',
              EN: 'BGN 70',
            },

            {
              BG: '105лв',
              EN: 'BGN 105',
            },
            {
              BG: '60лв',
              EN: 'BGN 60',
            },

            {
              BG: '120лв',
              EN: 'BGN 120',
            },

            {
              BG: '105лв',
              EN: 'BGN 105',
            },

            {
              BG: '200лв',
              EN: 'BGN 200',
            },

            {
              BG: '175лв',
              EN: 'BGN 175',
            },

            {
              BG: '60лв',
              EN: 'BGN 60',
            },

            {
              BG: '50лв',
              EN: 'BGN 50',
            },

            {
              BG: '20лв',
              EN: 'BGN 20',
            },

            {
              BG: '17,50лв',
              EN: 'BGN 17.50',
            },
          ],
        ],
      },
    },

    // Second table
    {
      heading: {
        BG: 'Ценоразпис на бюджетната стая (на нощувка)',
        EN: 'Pricing of the budget room (per night)',
      },

      tableContent: {
        headerText: [
          {
            BG: 'Период',
            EN: 'Period',
          },

          {
            BG: 'Цена за нощувка на човек',
            EN: 'Price per person per day',
          },

          {
            BG: 'Цена за нощувка за дете до 5 години – настаняване при родител',
            EN: 'Price per day For children up to 5 years old – accommodation with a parent',
          },

          {
            BG: 'Цена за нощувка на дете до 10 години	',
            EN: 'Price per day For children up to 10 years old',
          },

          {
            BG: 'Включена закуска да/не',
            EN: 'Breaksfast included – yes/no',
          },
        ],

        mainContentRows: [
          {
            period: {
              BG: '15.06.2022 – 30.06.2022',
              EN: '15.06.2022 – 30.06.2022',
            },

            pricePerPerson: {
              BG: '30лв.',
              EN: 'BGN30',
            },

            pricePerChildUpTo5YearsOld: {
              BG: 'Безплатен престой',
              EN: 'Free Stay',
            },

            pricePerChildUpTo10YearsOld: {
              BG: '25лв.',
              EN: 'BGN25',
            },

            breakfastIncluded: {
              BG: 'Не',
              EN: 'No',
            },
          },

          {
            period: {
              BG: '01.07.2022 – 15.07.2022',
              EN: '01.07.2022 – 15.07.2022',
            },

            pricePerPerson: {
              BG: '35лв.',
              EN: 'BGN35',
            },

            pricePerChildUpTo5YearsOld: {
              BG: 'Безплатен престой',
              EN: 'Free Stay',
            },

            pricePerChildUpTo10YearsOld: {
              BG: '30лв.',
              EN: 'BGN30',
            },

            breakfastIncluded: {
              BG: 'Да',
              EN: 'Yes',
            },
          },

          {
            period: {
              BG: '16.07.2022 – 25.08.2022',
              EN: '16.07.2022 – 25.08.2022',
            },

            pricePerPerson: {
              BG: '45лв.',
              EN: 'BGN45',
            },

            pricePerChildUpTo5YearsOld: {
              BG: 'Безплатен престой',
              EN: 'Free Stay',
            },

            pricePerChildUpTo10YearsOld: {
              BG: '35лв.',
              EN: 'BGN35',
            },

            breakfastIncluded: {
              BG: 'Да',
              EN: 'Yes',
            },
          },

          {
            period: {
              BG: '26.08.2022 – 15.09.2022',
              EN: '26.08.2022 – 15.09.2022',
            },

            pricePerPerson: {
              BG: '35лв.',
              EN: 'BGN35',
            },

            pricePerChildUpTo5YearsOld: {
              BG: 'Безплатен престой',
              EN: 'Free Stay',
            },

            pricePerChildUpTo10YearsOld: {
              BG: '30лв.',
              EN: 'BGN30',
            },

            breakfastIncluded: {
              BG: 'Да',
              EN: 'Yes',
            },
          },
        ],
      },
    },
  ],

  terms: {
    heading: {
      BG: 'Условия за използване',
      EN: 'Terms Of Use',
    },

    content: {
      BG: (
        <Fragment>
          <p>
            &ndash; Самостоятелно ползващ помещение, заплаща 80% от цената при пълен капацитет на
            помещението, което би желал да ползва.
          </p>
          <p>
            &ndash; Деца от 0 до 6 години без ползване на самостоятелно легло, се настаняват
            безплатно.
          </p>
          <p>
            &ndash; Цената за апартамента, е обявената в ценовата листа, независимо от колко души се
            ползва.
          </p>
          <p>
            &ndash; Максималният капацитет на запълване на апартамента е 5 души, като едното място е
            на разтегателен диван.
          </p>
          <p>
            &ndash; Закуската е на блок маса и включва еспресо, чай, сокове, млечни напитки, домашно
            приготвена закуска, както и разнообразни месни и млечни продукти, яйца, плодове,
            зеленчуци и др.
          </p>
          <p>
            &ndash; Паркомясто се заявява предварително и се осигурява до изчерпване на капацитета.
            Цената за денонощие е 5лв.
          </p>
        </Fragment>
      ),

      EN: (
        <Fragment>
          <p>
            &ndash; An individual user of a room pays 80% of the price at full capacity of the room
            he/she would like to use.
          </p>
          <br />
          <p>&ndash; Children from 0 to 6 years old without using a separate bed stay for free.</p>
          <p>
            &ndash; The price for the apartment is announced in the price list, regardless of how
            many people use it.
          </p>
          <p>
            &ndash; The maximum occupancy capacity of the apartment is 5 people, one of which is on
            a sofa bed.
          </p>
          <p>
            &ndash; Breakfast is a buffet and includes espresso, tea, juices, milk drinks, homemade
            breakfast, as well as a variety of meat and dairy products, eggs, fruits, vegetables,
            etc.
          </p>
          <p>
            &ndash; A parking space must be requested in advance and is provided until the capacity
            is exhausted. The price per day is BGN 5.
          </p>
        </Fragment>
      ),
    },
  },
};

export const aboutPageContent = {
  blockOurHotelHeading: {
    BG: 'Нашия хотел',
    EN: 'Our hotel',
  },

  blockOurHotelText: {
    BG: (
      <p className={addCSSClassName(pagesCSS, 'pages__text')}>
        Гостувайки при нас ще се потопите в очарователната атмосфера на малкия семеен хотел -
        гостоприемство, уют, спокойствие. Екипът ни ще се постарае да се почуствате по възможно
        най-комфортния начин, и да се насладите на хармония и незабравими морски преживявания.
      </p>
    ),

    EN: (
      <p className={addCSSClassName(pagesCSS, 'pages__text')}>
        Visiting us you will immerse you into the charming atmosphere of the small family guest
        house - hospitality, comfort, tranquility. Our team will make you feel as comfortable as
        possible, and enjoy the harmony and unforgettable sea experiences.
      </p>
    ),
  },

  blockLocationHeading: {
    BG: 'Местоположение',
    EN: 'Location',
  },

  blockLocationText: {
    BG: (
      <p className={addCSSClassName(pagesCSS, 'pages__text')}>
        Къща за гости Вивиан е разположен на 3 минутки от прекрасния Южен плаж на Китен и на 500м от
        плаж "Атлиман" /Северният плаж/. В центъра на града и в същото време на китна, тиха и
        спокойна уличка, което ще Ви предостави отлични условия за морска почивка.
      </p>
    ),

    EN: (
      <p className={addCSSClassName(pagesCSS, 'pages__text')}>
        Guest House Vivian is located 3 minutes from the beautiful South Beach of Kiten and 500
        meters from the beach "Atliman" / North Beach /. Located in the city center and at the same
        time on a beautiful, quiet and peaceful street, which will provide you with excellent
        conditions for a sea holiday.
      </p>
    ),
  },

  blockFindHereHeading: {
    BG: 'Какво ще откриете тук',
    EN: 'What you will find here',
  },

  blockFindHereText: {
    BG: (
      <Fragment>
        <p className={addCSSClassName(pagesCSS, 'pages__text')}>
          Тук Вие ще откриете любимото си място за почивка на Черноморието. Разполагаме с помещения
          подходящи за всеки вкус. Апартаменти, двойни, тройни, четворни стаи, както и бюджетни
          стаи.
        </p>

        <p className={addCSSClassName(pagesCSS, 'pages__text')}>
          Разполагаме със следните помещения : Апартамент, двойни стаи, обикновени тройни стаи,
          тройни стаи лукс, както и бюджетни стаи.
        </p>

        <p className={addCSSClassName(pagesCSS, 'pages__text')}>
          Всяка стая разполага със собствена тераса, климатик (с изключение на бюджетните стаи),
          санитарен възел, чисто нови комфортни легла, LED 32" телевизор с много програми, безплатен
          WIFI и хладилник.
        </p>
      </Fragment>
    ),

    EN: (
      <Fragment>
        <p className={addCSSClassName(pagesCSS, 'pages__text')}>
          Here you will find your favorite vacation spot on the Black Sea coast. We have rooms
          suitable for every taste. Apartments, double, triple, quadruple rooms and budget rooms.
        </p>

        <p className={addCSSClassName(pagesCSS, 'pages__text')}>
          We have the following rooms: Apartment, double rooms, ordinary triple rooms, luxury triple
          rooms, as well as budget rooms.
        </p>

        <p className={addCSSClassName(pagesCSS, 'pages__text')}>
          Each room has its own balcony, air conditioning (excluding budget rooms), bathroom, brand
          new comfortable beds, LED 32" TV with many programs, free WIFI and refrigerator.
        </p>
      </Fragment>
    ),
  },

  // 3 BLOCKS START
  blockPersonalAttitudeHeading: {
    BG: 'Персонално отношение',
    EN: 'Personal attitude',
  },

  blockPersonalAttitudeText: {
    BG: (
      <p className={addCSSClassName(pagesCSS, 'pages__text--small')}>
        Тук ще получите индивидуално отношение, усещане за спокойствие и уют и не на последно място
        вкусна, домашно приготвена с много любов закуска.
      </p>
    ),

    EN: (
      <p className={addCSSClassName(pagesCSS, 'pages__text--small')}>
        Here you will receive an individual attitude towards you, a feeling of calm and comfort and,
        last but not least, delicious, home-made, prepared with much love, breakfast.
      </p>
    ),
  },

  blockRoomsHeading: {
    BG: 'Помещения',
    EN: 'Rooms',
  },

  blockRoomsText: {
    BG: (
      <p className={addCSSClassName(pagesCSS, 'pages__text--small')}>
        Уютни помещения, оборудвани с всичко необходимо.
      </p>
    ),

    EN: (
      <p className={addCSSClassName(pagesCSS, 'pages__text--small')}>
        Cozy rooms equipped with everything you need.
      </p>
    ),
  },

  blockQualityChecksHeading: {
    BG: 'Проверка на качеството',
    EN: 'Quality control',
  },

  blockQualityChecksText: {
    BG: (
      <p className={addCSSClassName(pagesCSS, 'pages__text--small')}>
        За да осигурим максимално удовлетворение от престоя на гостите, ние многократно, но
        деликатно ще проверяваме качеството на предоставените услуги.
      </p>
    ),

    EN: (
      <p className={addCSSClassName(pagesCSS, 'pages__text--small')}>
        In order to ensure maximum satisfaction with the stay of the guests, we will repeatedly but
        delicately check the quality of the services provided.
      </p>
    ),
  },
  // 3 BLOCKS END
};

export const pageNotFoundContent = {
  heading: {
    BG: 'Страницата, която търсихте не беше намерена',
    EN: 'The page you were looking for was not found',
  },

  text: {
    BG: 'Моля опитайте отново по-късно или влезте в друга страница',
    EN: 'Please try again later or enter another page',
  },

  btnText: {
    BG: 'Към началото',
    EN: 'Back to homepage',
  },
};

export const thankYouPageContent = {
  BG: {
    heading: 'Благодарим за вашето съобщение!',
    text: 'Ще се свържем с вас във възможно най-кратък срок (обикновенно 1 до 2 дни)',
    btnText: 'Към началото',
  },

  EN: {
    heading: 'Thank you for your message!',
    text: 'We will contact you as soon as possible (usually 1 до 2 дни)',
    btnText: 'Back to homepage',
  },
};

export const logoContent = {
  src: logo,
  alt: {
    BG: 'Лого на Къща за Гости Вивиан',
    EN: "Guest house Vivian's logo",
  },
};

export const promoContent = {
  text: {
    BG: 'Добре дошли в Къща за Гости Вивиан',
    EN: 'Welcome to Guest House Vivian',
  },
};

export const locationMapContent = {
  mapTitleAttr: {
    BG: 'Местоположение',
    EN: 'Location',
  },

  contactInfoHeading: {
    BG: 'Контактна информация',
    EN: 'Contact Info',
  },
};

const roomsHeadingUrlInfo = [
  {
    heading: 'Apartment',

    getUrl() {
      return generateUrlPath(this.heading, 'rooms');
    },
  },

  {
    heading: 'Budget Room',

    getUrl() {
      return generateUrlPath(this.heading, 'rooms');
    },
  },

  {
    heading: 'Double room',

    getUrl() {
      return generateUrlPath(this.heading, 'rooms');
    },
  },

  {
    heading: 'Triple room',
    getUrl() {
      return generateUrlPath(this.heading, 'rooms');
    },
  },

  {
    heading: 'Triple Room Delux',

    getUrl() {
      return generateUrlPath(this.heading, 'rooms');
    },
  },
];

export const roomsUrls = roomsHeadingUrlInfo.map(cur => cur.getUrl());

export const specificRoomsContent = [
  {
    heading: {
      BG: 'Апартамент',
      EN: 'Apartment',
    },

    description: {
      BG: (
        <p>
          Апартаментът разполага с две самостоятелни стаи отделени с врата, кухненски бокс, маса за
          хранене, диван, два санитарни възела, два LED 32'"телевизора, безплатен Wi-Fi, хладилник,
          климатици. Всяко от помещенията е със самостоятелни тераси. Подходящ за до 5 човека.
        </p>
      ),
      EN: (
        <p>
          The apartment has two separate rooms separated by a door, kitchenette, dining table, sofa,
          two bathrooms, two LED 32" TVs, free Wi-Fi, refrigerator, air conditioning. Each of the
          rooms has private balconies. 5 people.
        </p>
      ),
    },

    images: [
      apartmentImg1,
      apartmentImg2,
      apartmentImg3,
      apartmentImg4,
      apartmentImg5,
      apartmentImg6,
      apartmentImg7,
      apartmentImg8,
      apartmentImg9,
      apartmentImg10,
      apartmentImg11,
    ],

    images2: [imgTripleRoom1, imgTripleRoom2, imgTripleRoom3, imgTripleRoom4],

    getUrl() {
      return generateUrlPath(this.heading.EN, 'rooms');
    },
  },

  {
    heading: {
      BG: 'Двойна стая',
      EN: 'Double room',
    },

    description: {
      BG: (
        <p>
          Максимално настаняване до 2 души, възможност за спалня или две единични легла. Разполага
          със собствена тераса, климатик, санитарен възел, чисто нови комфортни легла и обзавеждане,
          LED 32'" телевизор с кабелна телевизия, хладилник мини-бар и безплатен WiFi.
        </p>
      ),
      EN: (
        <p>
          Maximum occupancy up to 2 people, possibility for a bedroom or two single beds. It has its
          own terrace, air conditioning, bathroom, brand new comfortable beds and furniture, LED 32
          '"cable TV, refrigerator mini-bar and free WiFi.
        </p>
      ),
    },

    images: [imgDoubleRoom2, imgDoubleRoom3, imgDoubleRoom4],

    getUrl() {
      return generateUrlPath(this.heading.EN, 'rooms');
    },
  },

  {
    heading: {
      BG: 'Тройна стая',
      EN: 'Triple room',
    },

    description: {
      BG: (
        <Fragment>
          <p>
            Настаняване до трима възрастни. Разполага със собствена тераса, климатик, санитарен
            възел, чисто нови комфортни легла и обзавеждане, LED 32'" телевизор с кабелна телевизия,
            хладилник мини-бар и безплатен WiFi.
          </p>
        </Fragment>
      ),
      EN: (
        <Fragment>
          <p>
            Accommodation for up to 3 adults. It has its own terrace, air conditioning, bathroom,
            brand new comfortable beds and furniture, LED 32" TV, refrigerator, mini-bar and free
            WiFi.
          </p>
        </Fragment>
      ),
    },

    images: [imgTripleRoomNew1, imgTripleRoomNew2, imgTripleRoomNew3, imgTripleRoomNew4],

    getUrl() {
      return generateUrlPath(this.heading.EN, 'rooms');
    },
  },

  {
    heading: {
      BG: 'Тройна стая делукс',
      EN: 'Triple room delux',
    },

    description: {
      BG: (
        <Fragment>
          <p>
            Настаняване до 3+1 възрастни с допълнително /разтегателно/ легло при възможност /до
            изчерпване на количествата/ или бебешка кошарка, която се заявява предварително и се
            осигурява до изчерпване на наличните бройки.
          </p>

          <p>
            Разполага със собствена тераса, климатик, санитарен възел, чисто нови комфортни легла и
            обзавеждане, LED 32'" телевизор с кабелна телевизия, хладилник мини-бар и безплатен
            WiFi.
          </p>
        </Fragment>
      ),
      EN: (
        <Fragment>
          <p>
            Accommodation for up to 3 + 1 adults with extra / pull-out / bed if possible / until the
            quantities are exhausted / or baby cot, which is ordered in advance and is provided
            until the available units are exhausted.
          </p>

          <p>
            It has its own terrace, air conditioning, bathroom, brand new comfortable beds and
            furniture, LED 32" TV, refrigerator, mini-bar and free WiFi.
          </p>
        </Fragment>
      ),
    },

    pricing: [
      {
        title: {
          BG: '15.06 до 30.06',
          EN: '15.06 to 30.06',
        },

        description: {
          BG: <p>35лв на човек на ден без закуска</p>,
          EN: <p>BGN 35 per person per day without breakfast</p>,
        },
      },

      {
        title: {
          BG: '01.07 до 15.07',
          EN: '01.07 to 15.07',
        },

        description: {
          BG: (
            <p>
              35лв на човек на ден без закуска
              <br />
              За деца до 10 години – Със самостоятелно легло – 35лв на човек на ден с вкл. закуска
              <br />
              За деца до 5 години – настаняване при родител – престоят е безплатен
            </p>
          ),

          EN: (
            <p>
              BGN 40 per person per day with breakfast included
              <br />
              For children up to 10 years old – With separate bed – BGN 40 per person per day with
              breakfast included
              <br />
              For children up to 5 years old – accommodation with a parent – the stay is free
            </p>
          ),
        },
      },

      {
        title: {
          BG: '16.07 до 25.08',
          EN: '16.07 to 25.08',
        },

        description: {
          BG: (
            <p>
              50лв на човек на ден без закуска
              <br />
              За деца до 10 години – Със самостоятелно легло – 40лв на човек на ден с вкл. закуска
              <br />
              За деца до 5 години – настаняване при родител – престоят е безплатен
            </p>
          ),

          EN: (
            <p>
              BGN 50 per person per day with breakfast included
              <br />
              For children up to 10 years old – With separate bed – BGN 40 per person per day with
              breakfast included
              <br />
              For children up to 5 years old – accommodation with a parent – the stay is free
            </p>
          ),
        },
      },

      {
        title: {
          BG: '26.08 до 15.09',
          EN: '26.08 to 15.09',
        },

        description: {
          BG: (
            <p>
              40лв на човек на ден без закуска
              <br />
              За деца до 10 години – Със самостоятелно легло – 35лв на човек на ден с вкл. закуска
              <br />
              За деца до 5 години – настаняване при родител – престоят е безплатен
            </p>
          ),

          EN: (
            <p>
              BGN 40 per person per day with breakfast included
              <br />
              For children up to 10 years old – With separate bed – BGN 35 per person per day with
              breakfast included
              <br />
              For children up to 5 years old – accommodation with a parent – the stay is free
            </p>
          ),
        },
      },
    ],

    images: [
      imgTripleRoom2,
      imgTripleRoom5,
      imgTripleRoom7,
      imgTripleRoom10,
      imgTripleRoom11,
      imgTripleRoom12,
      imgTripleRoom13,
    ],

    images2: [imgTripleRoom1, imgTripleRoom2, imgTripleRoom3, imgTripleRoom4],

    getUrl() {
      return generateUrlPath(this.heading.EN, 'rooms');
    },
  },

  {
    heading: {
      BG: 'Бюджетна стая',
      EN: 'Budget room',
    },

    description: {
      BG: (
        <p>
          Бюджетната стая е подходяща за двама възрастни или възрастен с едно дете. Разполага със
          спалня с размер 140/200см, със собствена тераса, санитарен възел, LED 32'" телевизор с
          кабелна телевизия, хладилник мини-бар и безплатен WiFi.
        </p>
      ),
      EN: (
        <Fragment>
          <p>
            The budget room is suitable for two adults or an adult with one child. It has a bedroom
            of 140/200cm, with private terrace, bathroom, LED 32" TV, refrigerator mini-bar and free
            WiFi.
          </p>
        </Fragment>
      ),
    },

    images: [budgetRoomImg1, budgetRoomImg2, budgetRoomImg3, budgetRoomImg4, budgetRoomImg5],

    getUrl() {
      return generateUrlPath(this.heading.EN, 'rooms');
    },
  },
];

export const roomPageContent = {
  descriptionLabel: {
    BG: 'Описание',
    EN: 'Description',
  },

  pricingLabel: {
    BG: 'Ценоразпис',
    EN: 'Pricing',
  },

  termsLabel: {
    BG: 'Условия',
    EN: 'Room Terms',
  },

  btnLabel: {
    BG: 'Резервирайте сега',
    EN: 'Book now',
  },
};

export const gridItemContent = {
  btnText: {
    BG: 'Преглед',
    EN: 'View',
  },
};

export const roomsContent = {
  heading: {
    BG: 'Нашите помещения',
    EN: 'Our rooms',
  },

  rooms: [
    {
      img: {
        src: apartmentImg1,
        alt: {
          BG: 'Апартамент',
          EN: 'Apartment',
        },
      },

      heading: {
        BG: 'Апартамент',
        EN: 'Apartment',
      },

      prices: [150, 200],
      url: '/rooms/apartment',
    },

    {
      img: {
        src: imgTripleRoom2,
        alt: {
          BG: '',
          EN: '',
        },
      },

      heading: {
        BG: 'Тройна стая делукс',
        EN: 'Triple room delux',
      },

      prices: [150, 200],
      url: '/rooms/triple-room-delux',
    },

    {
      img: {
        src: imgTripleRoom8,
        alt: {
          BG: '',
          EN: '',
        },
      },

      heading: {
        BG: 'Двойна стая',
        EN: 'Double room',
      },

      prices: [150, 200],
      url: '/rooms/double-room',
    },

    {
      img: {
        src: imgTripleRoomNew3,
        alt: {
          BG: '',
          EN: '',
        },
      },

      heading: {
        BG: 'Тройна стая',
        EN: 'Triple room',
      },

      prices: [150, 200],
      url: '/rooms/triple-room',
    },

    {
      img: {
        src: budgetRoomImg1,
        alt: {
          BG: 'Бюджетна стая',
          EN: 'Budget room',
        },
      },

      heading: {
        BG: 'Бюджетна стая',
        EN: 'Budget room',
      },

      prices: [150, 200],
      url: '/rooms/budget-room',
    },
  ],
};

export const galleryContent = {
  heading: {
    BG: 'Нашата галерия',
    EN: 'Our gallery',
  },
};

export const contactsPageContent = {
  heading: {
    BG: 'Връзка с нас',
    EN: 'Contact us',
  },
};

export const headerContent = {
  navigation: {
    BG: navigation.BG,
    EN: navigation.EN,
  },
};

export const footerContent = {
  navigation: {
    BG: navigation.BG.find(menu => menu.name === 'footerNav'),
    EN: navigation.EN.find(menu => menu.name === 'footerNav'),
  },

  copyText: {
    BG: (
      <span>
        &copy;<span>{new Date().getFullYear()}</span> Всички права запазени.
      </span>
    ),

    EN: (
      <span>
        &copy;<span>{new Date().getFullYear()}</span> All Rights Reserved.
      </span>
    ),
  },
};

export const contactFormContent = {
  name: {
    BG: 'Име',
    EN: 'Your Name',
  },

  phone: {
    BG: 'Телефон',
    EN: 'Phone',
  },

  email: {
    BG: 'Имейл (По избор)',
    EN: 'Email (Optional)',
  },

  message: {
    BG: 'Съобщение',
    EN: 'Your message',
  },

  button: {
    text: {
      BG: 'Изпрати',
      EN: 'Send',
    },
  },
};

export const roomTermsContent = [
  {
    heading: {
      BG: 'Допълнително легло',
      EN: 'Extra bed',
    },

    description: {
      BG: 'Допълнителното легло се доплаща',
      EN: 'Extra bed is costs extra',
    },
  },

  {
    heading: {
      BG: 'Бебешка кошарка',
      EN: 'Baby cot',
    },

    description: {
      BG: 'Бебешката кошарка е безплатна, предоставя се с предварителна заявка, но до изчерпване на наличните бройки.',
      EN: 'The baby cot is free of charge, provided by prior request, but until the available quantities are exhausted.',
    },
  },

  {
    heading: {
      BG: 'Домашни любимци',
      EN: 'Pets',
    },

    description: {
      BG: 'Домашни любимци не се допускат.',
      EN: 'Pets are not allowed inside.',
    },
  },

  {
    heading: {
      BG: 'Паркинг',
      EN: 'Parking',
    },

    description: {
      BG: 'Платен паркинг - 5лв. на денонощие - ограничен брой места.',
      EN: 'Paid parking - BGN 5.00 per 24 hours - limited number of places',
    },
  },
];
