import { NavLink } from 'react-router-dom';
import css from './MainNav.module.scss';

import { addCSSClassName } from '../../../assets/js/helpers';

function MainNav(props) {
  const { nav } = props;

  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  function renderMenuItems(menu) {
    return menu.items.map(item => (
      <li key={Math.random() + 235 * Math.random()}>
        <NavLink
          className={`${addCSSClassName(css, 'main-nav__link')}`}
          to={item.link}
          activeClassName={`${addCSSClassName(css, 'main-nav__link--active')}`}
          exact
        >
          {item.title}
        </NavLink>
      </li>
    ));
  }

  return <ul className={`${css['main-nav']}`}>{renderMenuItems(nav)}</ul>;
}

export default MainNav;
