import { createPortal } from 'react-dom';
import { NavLink } from 'react-router-dom';
import { addCSSClassName } from '../../../assets/js/helpers';
import css from './MobNav.module.scss';
import { useMemo } from 'react';

const portalElement = document.getElementById('mob-nav-modal');

const MobNav = ({ menu }) => {
  const MenuItems = useMemo(() => {
    return () => {
      return menu.items.map(item => (
        <li key={Math.random() + 235 * Math.random()}>
          <NavLink
            className={`${addCSSClassName(css, 'mob-nav__link')}`}
            to={item.link}
            activeClassName={`${addCSSClassName(css, 'mob-nav__link--active')}`}
            exact
          >
            {item.title}
          </NavLink>
        </li>
      ));
    };
  }, [menu.items]);

  const clickHandler = e => {
    e.preventDefault();

    const modal = document.getElementById('mob-nav-modal');
    modal.classList.remove('mob-nav-modal-active');
  };

  const MobNavCode = useMemo(() => {
    return () => (
      <div className={addCSSClassName(css, 'overlay', 'mob-nav-overlay')} onClick={clickHandler}>
        <nav>
          <ul className={addCSSClassName(css, 'mob-nav')}>
            <MenuItems />
          </ul>
        </nav>
      </div>
    );
  }, []);

  return createPortal(<MobNavCode />, portalElement);
};

export default MobNav;
