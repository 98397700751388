import css from './Promo.module.scss';
import contact from '../../../assets/js/contacts';
import { promoContent as content } from '../../../assets/js/content';

import useLocale from '../../../hooks/useLocale';

const Promo = () => {
  const { lang } = useLocale();

  return (
    <div className={`${css.promo}`}>
      <a href={contact.phone.link} className="link">
        {contact.phone.text}
      </a>

      <span className={`${css['promo__text']}`}>{content.text[lang]}</span>

      <a href={contact.email.link} className="link">
        {contact.email.text}
      </a>
    </div>
  );
};

export default Promo;
