import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.scss';
import App from './App';
// import reportWebVitals from "./reportWebVitals";

import { LocaleContextProvider } from './store/locale-context';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <LocaleContextProvider>
        <App />
      </LocaleContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('app')
);
