import css from './Container.module.scss';

const Container = props => {
  const { classes, maxWidth } = props;

  return (
    <div
      style={{ maxWidth }}
      className={`${css.container}${classes ? ` ${classes}` : ``}`}
    >
      {props.children}
    </div>
  );
};

export default Container;
