import css from './Header.module.scss';
import { headerContent as content } from '../../../assets/js/content';
import MainNav from '../../Navigation/MainNav/MainNav';
import Promo from '../Promo/Promo';
import LanguageBar from '../../LanguageBar/LanguageBar';
import useLocale from '../../../hooks/useLocale';
import MobNavIcon from '../../Navigation/MobNavIcon/MobNavIcon';
import MobNav from '../../Navigation/MobNav/MobNav';

const Header = () => {
  const { lang } = useLocale();

  const { navigation } = content;
  const nav = navigation[lang].find(navi => navi.name === 'mainNav');

  return (
    <header className={`${css.header}`}>
      <Promo />
      <div className={`${css['header__nav-cont']}`}>
        <MainNav nav={nav} />

        <MobNavIcon style={{ paddingTop: '2.4rem' }} />
        <MobNav menu={nav} />

        <LanguageBar />
      </div>
    </header>
  );
};

export default Header;
