import { Link } from "react-router-dom";
import css from "./FooterNav.module.scss";

function FooterNav(props) {
  const footerMenu = props.nav;

  function renderMenuItems(menuItems) {
    return menuItems.map((item) => (
      <li key={Math.random() + 123 * Math.random() - 40}>
        <Link className={`${css["footer-nav__link"]}`} to={item.link}>
          {item.title}
        </Link>
      </li>
    ));
  }

  function renderMenu() {
    return (
      <nav>
        <ul className={`${css["footer-nav__list"]}`}>
          {renderMenuItems(footerMenu)}
        </ul>
      </nav>
    );
  }
  return renderMenu();
}

export default FooterNav;
