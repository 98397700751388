import { lazy as lazyLoad, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Container from './Components/Helpers/Container/Container';
import MainLayout from './Components/Layout/MainLayout';
import Header from './Components/Layout/Header/Header';
import LoaderSpinner from './Components/LoaderSpinner/LoaderSpinner';
import { logoContent, roomsUrls } from './assets/js/content';
import Footer from './Components/Layout/Footer/Footer';
import useLocale from './hooks/useLocale';
import './assets/styles/typography.scss';
import './assets/styles/utilities.scss';
import RoomPage from './Components/Pages/RoomPage';
import PageNotFound from './Components/Pages/NotFound';
import ScrollToMain from './Components/Helpers/ScrollToMain/ScrollToMain';

const ScrollToTop = ScrollToMain;

// Pages (Lazy loading)
const HomePage = lazyLoad(() => import('./Components/Pages/HomePage'));
const AboutPage = lazyLoad(() => import('./Components/Pages/AboutPage'));
const ContactsPage = lazyLoad(() => import('./Components/Pages/ContactsPage'));
const GalleryPage = lazyLoad(() => import('./Components/Pages/GalleryPage'));
const PrivacyPage = lazyLoad(() => import('./Components/Pages/Legal/PrivacyPage'));
const RoomsPage = lazyLoad(() => import('./Components/Pages/RoomsPage'));
const TermsPage = lazyLoad(() => import('./Components/Pages/Legal/TermsPage'));

const App = () => {
  const { lang: curLang } = useLocale();

  return (
    <Container>
      {/* Logo */}
      <img className="logo" src={logoContent.src} alt={logoContent.alt[curLang]} />

      <Header />

      <MainLayout>
        <Suspense fallback={<LoaderSpinner />}>
          <Switch>
            <Route path="/" exact>
              <ScrollToTop>
                <HomePage />
              </ScrollToTop>
            </Route>

            <Route path="/about/" exact>
              <ScrollToTop>
                <AboutPage />
              </ScrollToTop>
            </Route>

            <Route path="/contacts" exact>
              <ScrollToTop>
                <ContactsPage />
              </ScrollToTop>
            </Route>

            <Route path="/hotel/gallery" exact>
              <ScrollToTop>
                <GalleryPage />
              </ScrollToTop>
            </Route>
            <Route path="/park" exact>
              <ScrollToTop></ScrollToTop>
            </Route>
            <Route path="/hotel" exact>
              <Redirect to="/" />
            </Route>

            <Route path="/hotel/rooms" exact>
              <ScrollToTop>
                <RoomsPage />
              </ScrollToTop>
            </Route>

            {roomsUrls.map(url => (
              <Route key={Math.random()} path={url}>
                <ScrollToTop>
                  <RoomPage />
                </ScrollToTop>
              </Route>
            ))}

            <Route path="/legal" exact>
              <Redirect to="/legal/terms" />
            </Route>

            <Route path="/legal/terms" exact>
              <ScrollToTop>
                <TermsPage />
              </ScrollToTop>
            </Route>

            <Route path="/legal/privacy" exact>
              <ScrollToTop>
                <PrivacyPage />
              </ScrollToTop>
            </Route>

            <Route path="*">
              <ScrollToTop>
                <PageNotFound />
              </ScrollToTop>
            </Route>
          </Switch>
        </Suspense>
      </MainLayout>

      <Footer />
    </Container>
  );
};

export default App;
