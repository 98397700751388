import css from './Button.module.scss';

import { addCSSClassName } from '../../assets/js/helpers';

const Button = props => {
  const { children, outline, type, onClick, className } = props;
  let classes = addCSSClassName(css, 'btn');

  if (outline) {
    classes += ` ${addCSSClassName(css, 'btn--outline')}`;
  }

  return (
    <button
      onClick={onClick}
      type={type}
      className={`${classes}${className ? ` ${className}` : ''}`}
    >
      {children}
    </button>
  );
};

export default Button;
