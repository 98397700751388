export const bgNav = [
  {
    name: 'mainNav',
    items: [
      {
        title: 'Начало',
        link: '/',
      },

      {
        title: 'Стаи',
        link: '/hotel/rooms',
      },

      {
        title: 'Галерия',
        link: '/hotel/gallery',
      },

      {
        title: 'Контакти',
        link: '/contacts',
      },

      {
        title: 'За нас',
        link: '/about',
      },
    ],
  },

  {
    name: 'footerNav',
    items: [
      {
        title: 'Условия за използване',
        link: '/legal/terms',
      },

      {
        title: 'Поверителност',
        link: '/legal/privacy',
      },

      {
        title: 'Стаи',
        link: '/hotel/rooms',
      },

      {
        title: 'Контакти',
        link: '/contacts',
      },

      {
        title: 'За нас',
        link: '/about',
      },
    ],
  },
];

export const enNav = [
  {
    name: 'mainNav',
    items: [
      {
        title: 'Home',
        link: '/',
      },

      {
        title: 'Rooms',
        link: '/hotel/rooms',
      },

      {
        title: 'Gallery',
        link: '/hotel/gallery',
      },

      {
        title: 'Contacts',
        link: '/contacts',
      },

      {
        title: 'About us',
        link: '/about',
      },
    ],
  },

  {
    name: 'footerNav',
    items: [
      {
        title: 'Terms of Use',
        link: '/legal/terms',
      },

      {
        title: 'Privacy',
        link: '/legal/privacy',
      },

      {
        title: 'Rooms',
        link: '/hotel/rooms',
      },

      {
        title: 'Contacts',
        link: '/contacts',
      },

      {
        title: 'About us',
        link: '/about',
      },
    ],
  },
];

const navigation = {
  BG: bgNav,
  EN: enNav,
};

export default navigation;
