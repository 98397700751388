import css from './TextBox.module.scss';

import { addCSSClassName } from '../../assets/js/helpers';

const TextBox = props => {
  const { styles, classes } = props;

  return (
    <div className={addCSSClassName(css, 'text-box', classes)} style={styles}>
      {props.children}
    </div>
  );
};

export default TextBox;
