import { Fragment } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import {
  specificRoomsContent as mainContent,
  roomTermsContent,
  roomPageContent,
} from '../../assets/js/content';
import useLocale from '../../hooks/useLocale';
import Button from '../Buttons/Button';
import RoomTerms from '../RoomTerms/RoomTerms';
import Slider from '../Slider/Slider';
import TextBox from '../TextBox/TextBox';

import css from './Pages.module.scss';

const RoomPage = () => {
  const { lang } = useLocale();
  const path = useLocation().pathname;
  const navigateTo = useHistory().push;

  const roomObj = mainContent.find(room => room.getUrl() === path);
  const { heading, description, images } = roomObj;
  const { descriptionLabel, termsLabel, btnLabel } = roomPageContent;

  const ctaClickHandler = e => {
    e.preventDefault();
    navigateTo('/contacts');
  };

  return (
    <Fragment>
      <h1
        className={`heading__secondary u-center-text${
          images.length === 0 ? ' u-margin-bottom-md' : ''
        }`}
      >
        {heading[lang]}
      </h1>

      <Slider
        className="u-margin-bottom-md"
        images={images}
        style={{
          boxShadow: '0 2rem 4rem rgba(0,0,0,.3)',
          borderRadius: '5px',
        }}
      />

      <h2 className="heading__secondary u-center-text u-margin-bottom-sm">
        {descriptionLabel[lang]}
      </h2>
      <TextBox
        styles={{
          padding: '4rem 2.4rem',
          width: '90%',
          margin: '0 auto 8rem',
          textAlign: 'center',
          fontSize: '2rem',
        }}
        classes={['center', 'u-margin-bottom-md']}
      >
        {description[lang]}
      </TextBox>

      <h2 className="heading__secondary u-center-text u-margin-bottom-sm">{termsLabel[lang]}</h2>
      <RoomTerms css={css} content={roomTermsContent} />

      <div style={{ width: '100%', textAlign: 'center' }}>
        <Button onClick={ctaClickHandler} className="u-center-text">
          {btnLabel[lang]}
        </Button>
      </div>
    </Fragment>
  );
};

export default RoomPage;
