import { useContext } from 'react';
import localeContext from '../store/locale-context';

/**
 * @access This hook gives us access to the Locale context API.
 * @purpose It is created in order not to repeat the same code all over again and to be able to add more functionality later if needed
 */
const useLocale = () => {
  const localeCtx = useContext(localeContext);

  return localeCtx;
};

export default useLocale;
