import { createContext, useState } from 'react';

const localeContext = createContext({
  lang: 'BG',
  switchLanguage: () => {},
});

export const LocaleContextProvider = ({ children }) => {
  const [lang, setLang] = useState('BG');

  const switchLanguage = newLang => {
    setLang(newLang);
  };

  const finalValue = { lang, switchLanguage };

  return <localeContext.Provider value={finalValue}>{children}</localeContext.Provider>;
};

export default localeContext;
